<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import systemService from '@/api/systemService.js';
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

export default {
  props: {
    form_data: {
      type: Object,
      default: () => ({})
    },
    edit_permission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: "Car Brand",
      brands: [],
      vehicle_models: [],
      brand_id: null,
      selectedBrandId: null,
      newBrand: '',
      newVehicleModel: '',
      editBrandName: {},
      editVehicleModelName: {},
      showEditSuccessModal: false,
      brandToDelete: null,
      vehicleModelToDelete: null
    };
  },
  methods: {
    showAsOptions(arr) {
      return arr.map((item) => ({ value: item.id, text: item.name }));
    },
    fetchBrands() {
      return systemService.getAllBrands()
        .then(brands => {
          this.brands = brands;
          brands.forEach(brand => {
            this.editBrandName[brand.id] = brand.name;
          });
        })
        .catch(error => {
          console.error('Failed to fetch brands:', error);
        });
    },
    fetchVehicleModels() {
      return systemService.getAllVehicleModels()
        .then(vehicle_models => {
          // Filter out the deleted vehicle models
          this.vehicle_models = vehicle_models.filter(model => model.deleted !== 1);
          this.vehicle_models.forEach(model => {
            this.editVehicleModelName[model.id] = model.name;
          });
        })
        .catch(error => {
          console.error('Failed to fetch vehicle models:', error);
        });
    },
    init_options() {
      return Promise.all([this.fetchBrands(), this.fetchVehicleModels()]);
    },
    detectData() {
      if (this.form_data) {
        this.brand_id = this.form_data.brand_id;
      }
    },
    assignData() {
      this.$nextTick(this.detectData);
    },
    addBrand() {
      if (this.newBrand) {
        systemService.addBrand({ name: this.newBrand })
          .then(() => {
            this.newBrand = '';
            return this.fetchBrands();
          })
          .catch(error => {
            console.error('Failed to add brand:', error);
          });
      }
    },
    addVehicleModel() {
      if (this.newVehicleModel) {
        systemService.addVehicleModel({ name: this.newVehicleModel, brand_id: this.selectedBrandId })
          .then(() => {
            this.newVehicleModel = '';
            return this.fetchVehicleModels();
          })
          .catch(error => {
            console.error('Failed to add vehicle model:', error);
          });
      }
    },
    editVehicleModel(id) {
      if (this.editVehicleModelName[id]) {
        systemService.updateVehicleModel(id, { name: this.editVehicleModelName[id] })
          .then(updatedModel => {
            const index = this.vehicle_models.findIndex(model => model.id === id);
            if (index !== -1) {
              this.vehicle_models.splice(index, 1, updatedModel);
            }
          })
          .catch(error => {
            console.error('Failed to edit vehicle model:', error);
          });
      }
    },
    deleteVehicleModel(id) {
      this.vehicleModelToDelete = id;  // Save the id of the vehicle model to delete
      this.$bvModal.show('delete-vehicle-model-confirmation-modal');  // Show the confirmation modal
    },
    confirmDeleteVehicleModel() {
      systemService.deleteVehicleModel(this.vehicleModelToDelete)
        .then(() => {
          this.fetchVehicleModels(); // Fetch updated vehicle models after deletion
        })
        .catch(error => {
          console.error('Failed to delete vehicle model:', error);
        });
    },

    editBrand(id) {
      if (this.editBrandName[id]) {
        systemService.updateBrand(id, { name: this.editBrandName[id] })
          .then(updatedBrand => {
            const index = this.brands.findIndex(brand => brand.id === id);
            if (index !== -1) {
              this.brands.splice(index, 1, updatedBrand);
            }
            this.showEditSuccessModal = true;
          })
          .catch(error => {
            console.error('Failed to edit brand:', error);
          });
      }
    },
    deleteBrand(id) {
      this.brandToDelete = id;  // Save the id of the brand to delete
      this.$bvModal.show('delete-confirmation-modal');  // Show the confirmation modal
    },
    confirmDelete() {
      systemService.deleteBrand(this.brandToDelete)
        .then(() => {
          this.fetchBrands(); // Fetch brands again after successful deletion
          this.selectedBrandId = null; // Reset selectedBrandId to clear the filtered list
        })
        .catch(error => {
          console.error('Failed to delete brand:', error);
        });
    },

    selectBrand(id) {
      this.selectedBrandId = id;
    },

  },
  computed: {
    filteredVehicleModels() {
      if (this.selectedBrandId) {
        return this.vehicle_models.filter(model => model.brand_id === this.selectedBrandId);
      } else {
        return this.vehicle_models;
      }
    },
    selectedBrand() {
      return this.brands.find(brand => brand.id === this.selectedBrandId);
    },
  },
  mounted() {
    this.init_options();
    this.assignData();
  },
  components: {
    Layout,
    PageHeader
  }
};
</script>

<!-- The template and style sections remain the same -->

<template>
  <Layout>
    <PageHeader :title="title" />
    <div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="New Brand" label-for="newBrand" class="mb-3 brand-form-group">
            <b-form-input id="newBrand" v-model="newBrand" placeholder="Enter new brand"
              class="brand-input"></b-form-input>
            <b-button @click="addBrand" class="brand-button">Add Brand</b-button>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="list-group">
            <div class="list-group-item" v-for="brand in brands" :key="brand.id" @click="selectBrand(brand.id)">
              <p>{{ brand.name }}</p>
              <b-form-input id="editBrand" v-model="editBrandName[brand.id]" placeholder="Edit brand"
                class="brand-input"></b-form-input>
              <button class="edit-button" @click="editBrand(brand.id)">Edit</button>
              <button class="delete-button" @click="deleteBrand(brand.id)">Delete</button>
            </div>
          </div>
        </div>
        <b-modal id="delete-vehicle-model-confirmation-modal" title="Delete Vehicle Model" @ok="confirmDeleteVehicleModel">
          Are you sure you want to delete this vehicle model?
        </b-modal>
        <b-modal id="delete-confirmation-modal" title="Delete Brand" @ok="confirmDelete">
          Are you sure you want to delete this brand?
        </b-modal>
        <b-modal id="edit-success-modal" v-model="showEditSuccessModal" title="Success">
          Brand edited successfully.
        </b-modal>
        <div class="col-md-4" v-if="selectedBrandId !== null">
          <h2>Vehicle Model: {{ selectedBrand.name }}</h2>
          <div class="list-group">
            <div class="list-group-item" v-for="model in filteredVehicleModels" :key="model.id">
              <p>{{ model.name }}</p>
              <b-form-input id="editVehicleModel" v-model="editVehicleModelName[model.id]"
                placeholder="Edit vehicle model" class="vehicle-model-input"></b-form-input>
              <button class="edit-button" @click="editVehicleModel(model.id)">Edit</button>
              <button class="delete-button" @click="deleteVehicleModel(model.id)">Delete</button>
            </div>
          </div>

          <b-form-group label="New Vehicle Model" label-for="newVehicleModel" class="mb-3 vehicle-model-form-group">
            <b-form-input id="newVehicleModel" v-model="newVehicleModel" placeholder="Enter new vehicle model"
              class="vehicle-model-input"></b-form-input>
            <b-button @click="addVehicleModel" class="vehicle-model-button">Add Vehicle Model</b-button>
          </b-form-group>
        </div>

      </div>


    </div>
  </Layout>
</template>
<style scoped>
.brand-form-group {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.brand-input {
  margin-bottom: 10px;
}

.brand-button {
  background-color: #007bff;
  color: white;
}

.vehicle-model-form-group {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.vehicle-model-input {
  margin-bottom: 10px;
}

.vehicle-model-button {
  background-color: #007bff;
  color: white;
}

.list-group {
  margin-bottom: 20px;
}

.list-group-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

.col-md-4 {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

.edit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c82333;
}
</style>